import {isMobile} from 'mobile-device-detect';
import $ from "jquery"
import jQueryBridget from "jquery-bridget";
import Isotope from "isotope-layout"
import "readmore-js/readmore"
import "jquery-form"
import "jquery.maskedinput/src/jquery.maskedinput"
import "jquery.scrollbar"
import "popper.js"
import "./bootstrap"
import "magnific-popup/dist/jquery.magnific-popup"
import "slick-carousel"
import "jgrowl"
import notie from "notie/src/notie"

window.jQuery = window.$ = $;
jQueryBridget('isotope', Isotope, $);

jQuery(function ($) {

  if (isMobile) {
    $('html').addClass('mobile');
  }

  // Инпуты

  $('.input-group .form-control').on("focus", function () {
    $(this).parents(".input-group").addClass("focus")
  });

  $('.input-group .form-control').on("blur", function () {
    $(this).parents(".input-group").removeClass("focus")
  });

  $("input[name=phone]").mask("+7 999 999 99 99");

  // Аккордион Районов

  $('.districts-block-content-js').readmore({
    collapsedHeight: 40,
    speed: 200,
    moreLink: '<button class="btn btn-primary" type="button"><span class="text">Показать все районы</span></button>',
    lessLink: '<button class="btn btn-primary" type="button"><span class="text">Скрыть</span></button>',
  });

  // Скроллбар

  $('.scrollbar-outer').scrollbar();

  // Аккордион

  $('.accordion .btn').on("click", function () {
    $('.accordion .card-header').each(function () {
      $(this).removeClass("active");
    });
    if ($(this).attr("aria-expanded") === "false") {
      $(this).parents(".card-header").addClass("active")
    }
  });

  // Кнопки фильтра

  const filterChanger = () => {
    $('.filters-js').each(function () {
      const $reset = $(this).find('button[type="reset"]')
      $reset.addClass('active')
      $(this).find('input').each(function () {
        if ($(this).prop('checked')) {
          $reset.removeClass('active')
          return false;
        }
      })
    });
  }
  filterChanger()
  $(document).on('mse2_load', function (e, data) {
    // console.log(e, data);
    filterChanger()
  });

  // Слайдер

  const initSlider = slider => {
    slider.slick({
      swipe: true,
      draggable: true,
      dots: true,
      dotsClass: 'slick-dots',
      arrows: false,
      prevArrow: '<button type="button" class="slick-prev slick-arrow"><span></span></button>',
      nextArrow: '<button type="button" class="slick-next slick-arrow"><span></span></button>',
      infinite: true,
      pauseOnHover: false,
      fade: false,
      cssEase: 'ease-in-out',
      vertical: false,
      centerMode: false,
      centerPadding: '0px',
      adaptiveHeight: false,
      speed: 750,
      autoplay: typeof slider.data('autoplay') == 'boolean' ? slider.data('autoplay') : true,
      autoplaySpeed: 5000,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }

  const uninitSlider = slider => {
    slider.slick("unslick");
  }

  $('.reviews-block-js').each(function () {

    $(this).find('.nav-pills a').on('shown.bs.tab', function (event) {

      $("#" + event.target.href.split("#")[1]).find('.review-slider-js').each(function () {
        initSlider($(this))
      });

      $("#" + event.relatedTarget.href.split("#")[1]).find('.review-slider-js').each(function () {
        uninitSlider($(this))
      });

    });

    $(this).find('.review-slider-js').each(function (index) {
      if (index === 0) {
        initSlider($(this))
      }
    })
  });


  // Уведомления

  $.prototype.jGrowl = (text, options) => {

    // console.log(text, options);

    if (text && text !== 'close') {
      const type = options.theme.split("-").pop();
      const stay = !!options.sticky;

      // notie.alert({
      //   type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
      //   text: "String",
      //   stay: false, // optional, default = false
      //   time: 3, // optional, default = 3, minimum = 1,
      //   position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
      // });

      notie.alert({
        type,
        text,
        stay
      });
    }
  };

  // Галерея

  if ($('.gallery-js').length) {

    $('.gallery-js').each(function () {
      $(this).magnificPopup({
        delegate: 'a.gallery-item-js',
        type: 'image',
        fixedContentPos: false,
        fixedBgPos: true,
        overflowY: 'auto',
        closeBtnInside: true,
        preloader: false,
        tClose: 'Закрыть (Esc)',
        tLoading: 'Загрузка изображения #%curr%...',
        removalDelay: 300,
        closeOnContentClick: true,
        midClick: true,
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1],
          tPrev: 'Назад (Клавиша ←)',
          tNext: 'Вперед (Клавиша →)',
          tCounter: '%curr% / %total%',
        },
        image: {
          tError: '<a href="%url%">Изображение #%curr%</a> недоступно.',
          titleSrc: (item) => {
            const title = item.el.data('title') ? item.el.data('title') : '';
            const description = item.el.data('description') ? item.el.data('description') : '';
            return title + '<small>' + description + '</small>';
          }
        },
        mainClass: 'mfp-zoom-in',
        callbacks: {
          elementParse: function (item) {
            // Function will fire for each target element
            // "item.el" is a target DOM element (if present)
            // "item.src" is a source that you may modify
            // console.log(item.src.search(/youtube/i));
            if (item.src.search(/youtube/i) !== -1) {
              item.type = 'iframe';
            }
          },
          beforeOpen() {
            $('html').addClass('popup-open')
          },
          afterClose() {
            $('html').removeClass('popup-open')
          },
          imageLoadComplete() {
            setTimeout(() => {
              this.wrap.addClass('mfp-image-loaded')
            }, 16);
          },
          open() {
            $.magnificPopup.instance.next = function () {
              this.wrap.removeClass('mfp-image-loaded');
              setTimeout(() => {
                $.magnificPopup.proto.next.call(this)
              }, 120);
            };
            $.magnificPopup.instance.prev = function () {
              this.wrap.removeClass('mfp-image-loaded');
              setTimeout(() => {
                $.magnificPopup.proto.prev.call(this)
              }, 120);
            }
          }
        }
      });
    });

  }

  // Фильтр галереи

  const $grid = $('.grid').isotope({
    itemSelector: '.gallery-item',
    layoutMode: 'fitRows'
  });

  const getHeight = $gallery => {
    const h = parseInt($gallery.find('.gallery-item').height());
    const m = parseInt($gallery.find('.gallery-item').css("margin-bottom"));
    return h + m;
  }

  const readmoreInit = $gallery => {
    let startOpen = false;
    $gallery.readmore({
      collapsedHeight: 2 * getHeight($gallery),
      speed: 200,
      moreLink: '<button class="btn btn-primary" type="button"><span class="text">Показать еще фото</span></button>',
      lessLink: '<button class="btn btn-primary" type="button"><span class="text">Скрыть</span></button>',
      startOpen,
      afterToggle: function () {
        startOpen = !startOpen;
      }
    });
  }

  const readmoreInitAll = () => {
    $('.gallery-more-js').each(function () {
      $(this).readmore('destroy');
      readmoreInit($(this));
    })
  }

  readmoreInitAll();

  $(window).resize(function () {
    readmoreInitAll()
  });

  // filter functions
  const filterFns = {
    // show if number is greater than 50
    numberGreaterThan50: function () {
      const number = $(this).find('.number').text();
      return parseInt(number, 10) > 50;
    },
    // show if name ends with -ium
    ium: function () {
      const name = $(this).find('.name').text();
      return name.match(/ium$/);
    }
  };
  // bind filter button click
  $('.filters-button-group').on('click', 'button', function () {
    let filterValue = $(this).attr('data-filter');
    // use filterFn if matches value
    filterValue = filterFns[filterValue] || filterValue;
    $grid.isotope({filter: filterValue});

    const $currentGall = $(this).parents('.button-group').next().find('.gallery-more-js');

    if (filterValue !== "*") {
      $currentGall.readmore('destroy');
    } else {
      readmoreInit($currentGall);
    }


  });
  // change active class on buttons
  $('.button-group').each(function (i, buttonGroup) {
    const $buttonGroup = $(buttonGroup);
    $buttonGroup.on('click', 'button', function () {
      $buttonGroup.find('.active').removeClass('active');
      $(this).addClass('active');
    });
  });


  // Модалки

  $('[data-trigger="popup"]').on("click", e => {

    e.preventDefault();
    e.stopPropagation();
    const magnificPopup = $.magnificPopup.instance;
    const position = $(window).scrollTop();

    magnificPopup.open({
      items: {
        src: "#" + $(e.target).data('target'),
        trigger: $(e.target),
      },
      type: 'inline',
      fixedContentPos: false,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      removalDelay: 500,
      tClose: 'Закрыть (Esc)',
      midClick: true,
      callbacks: {
        elementParse: function (item) {
          // console.log(item); F3ZC5fWfDb
          if (item.src.search(/youtube/i) !== -1) item.type = 'iframe'
        },
        change: function () {
          $(this.content).find('.js-title').text(this.currItem.data.trigger.data('title') || "");
          $(this.content).find('.js-textarea').text(this.currItem.data.trigger.data('text') || "");

          // $(this.content).find('form').on("reset", () => {
          //   magnificPopup.close();
          // });
          //
          // $(this.content).find('form').on("submit", e => {
          //   if (this.currItem.data.trigger.data('onsubmit')) {
          //     e.preventDefault();
          //     eval(this.currItem.data.trigger.data('onsubmit'));
          //   }
          //   magnificPopup.close();
          // })

          $(document).on('af_complete', function (event, response) {
            // console.log(response);
            response.success && magnificPopup.close();
          })
        },

        beforeOpen: function () {
          this.st.mainClass = this.items[0].trigger.data('effect');
          $('html').addClass('popup-open');
        },

        beforeClose: function () {
          $(this.content).find('form').trigger("reset");
        },

        afterClose: function () {
          $('html').removeClass('popup-open');
          $(window).scrollTop(position);
        }

      }
    });
  });

  $(document).scroll(function () {
    if ($('html,body').scrollTop() > 100) {
      $('#go-to-top').addClass('show')
    } else {
      $('#go-to-top').removeClass('show')
    }
  });


  $('#go-to-top').click(function () {
    $('html,body').animate({scrollTop: 0}, 'slow');
    $(this).removeClass('show').blur();
    return false;
  });

  $(".go-to-block").click(function () {
    const link = "#" + $(this).prop("href").split("#")[1];
    $('html, body').animate({scrollTop: $(link).offset().top}, 'slow');
    return false;
  });


});
